import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NEXT_PUBLIC_NODE_ENV;
const isLocalDev = ENV === 'development';

if (!isLocalDev) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    beforeSend(event) {
      if (!SENTRY_DSN) {
        console.debug('SENTRY LOG:', event);
        return null;
      }
      return event;
    },
    tracesSampleRate: 1.0,
  });
}
